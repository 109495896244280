/* eslint-disable */

"use strict";
import {
  PublicKey,
  SystemProgram,
  Transaction,
  SYSVAR_CLOCK_PUBKEY,
  Keypair,
} from "@solana/web3.js";
import bs58 from "bs58";
import { TOKEN_PROGRAM_ID, Token, AccountLayout } from "@solana/spl-token";
import {
  getBalanceSingle,
  getTokenAccounts,
  getTokenAccountMaxAmount,
} from "../getBalance";
import { readLittleInt64, readUInt8 } from "../readBinary";
import { StakingInstruction } from "./instruction.js";
import { sleep, timeout } from "../timeout";
import { feeDiscount } from "../feeDiscount.js";
import { signAndSendTransaction } from "../sendTransaction";

// state size
const REGISTRAR_SIZE = 32 + 1 + 8 + 32 * 5 + 8 + 32;
const MEMBER_SIZE = 32 * 7 + 8 + 1;
const REWARD_VENDOR_SIZE = 32 * 7 + 1 + 8 + 8 + 8;

// seed
const seedPre = "0424";
export const rewardSeed = seedPre + "SypStkR";
export const memberSeed = seedPre + "SypStkM";
export const rewardVendorSeed = seedPre + "SypStkV";

// public key
const programId = "CaAwtLCRzM4SQ6vs4CiRkzy53MGeaVzCbB7ZuuxjBmZy";
export const stakingProgramId = programId;
// np mint is general
export const stakingList = [
  {
    registrarKey: "Af8xKX75wKZ3vGwS2w1Wc1pveT5YNFvBx1mYzLiWdFy5",
    lpMint: "BJqBaNSu61XKJFypnaVr7yycNbvWJ94MoVADM7hqrxjv",
    mpMint: "23n3gAdTtrqKwPWpLozP2Bw7RVrnNy2QKoGFScbphRsP",
    vendorKey: "F6UQVdQqoTuHfM6NnyLom7eMa89sv73iaweTjGi1EprE",
    vendorPDAKey: "6YYEw9ALcqjduU6rZXPVHNYJtjoVpnNs1RhU9nZauKfH",
    memberSeed: memberSeed,
    lockDay: 0,
    lockDayStr: "No Lockup",
    apy: 0.09,
  },
  {
    registrarKey: "H6Q8tTj3V977u3AYTTMebRBGZdXfDbfWfRNCdmrAfBv1",
    lpMint: "DKKP3ETgkLcts4UCB2ugEY351XzobbY3P8kvJfJRv5Dt",
    mpMint: "GQdRtKUd36tL1LLVfSCYvdDejXwPBnYoPgbgCnVm17Fx",
    vendorKey: "AzfoD4ud1L29z9fdYK26QXbQxwSRTVF3sDG8X4JdJk6D",
    vendorPDAKey: "dYHHaNDG9Xf4qkyp5A62pSdVUQWE8gU9PVJkGXVoHv2",
    memberSeed: memberSeed + "30",
    lockDay: 30,
    lockDayStr: "1 Month",
    apy: 0.12,
  },
  {
    registrarKey: "DEMC2cTaWk5idcQeosmq3UPfUAUtdemXPPW7P2vHNXZG",
    lpMint: "3c6y4J5YqHX9sWaU2E9ZCJcdR1xsMSwPizrddy6XS4Fp",
    mpMint: "9pNbUG1dPYc8aUFGqz2A6xyeqf1PScwyq3TdKDo9dHBs",
    vendorKey: "2rsyVjzD6LdP2bFACfYdbj78JLSsznByYW4CmuufbJmC",
    vendorPDAKey: "7Sk58aTjuTmH4Bt8Zwg3nogdG3r87bH8fAGmBgRAD8XH",
    memberSeed: memberSeed + "90",
    lockDay: 90,
    lockDayStr: "3 Months",
    apy: 0.18,
  },
  {
    registrarKey: "GKArKcS3GKrTCBSAr4pskTEoz54h81A3MFqrJ9ssMQkJ",
    lpMint: "3YR8t9uAjdCyuRy9taF4VkJY6KmwFrHDAPB8jGcYNWe4",
    mpMint: "vuhb7M7bLwZFS8GEHM1GwWej2j1z8BvzoCMdDPUSTN8",
    vendorKey: "8HYNpZympe6UKWkxomQZQRvHvpN75qcBdeMsr3qeWkJS",
    vendorPDAKey: "Fzns7xoyKWJ81VZwdCdehpmxN2M6KL8HuSgLLddEAgdH",
    memberSeed: memberSeed + "180",
    lockDay: 180,
    lockDayStr: "6 Months",
    apy: 0.24,
  },
  {
    registrarKey: "FNzuH1ZbQZbSFfAd9favq5dMKybKmwehdk1qurBBdgLv",
    lpMint: "GoXGz5pPUHwvUTaPQoeDDdM7JixMUzPppxT3y11xKuEe",
    mpMint: "9RzGPNKaSESUFDxhYCeecqqVfW67Lze6usj9wSXAh3Bw",
    vendorKey: "HiPT5qBnYfZZPe8QicrvHLUnCuVR1iKdtt26PKFeDUkL",
    vendorPDAKey: "AGn4A6CDXP8oMXYBU1pQaxpBb9Y4cRDgHr9MdsQ3dMyp",
    memberSeed: memberSeed + "365",
    lockDay: 365,
    lockDayStr: "1 Year",
    apy: 0.32,
  },
];

// token
const SYPMINT = "FnKE9n6aGjQoNWRBZXy4RW6LZVao7qwBonUbiD7edUmZ";
const USDCMINT = "EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v";
const sypAcc = new PublicKey(SYPMINT);
export const npMintKey = "6MXp7K7LE83aqTfqfg1tRsX2PdksBQZx6THCXmimEuVY";
const npAcc = new PublicKey(npMintKey);

// account
const programAcc = new PublicKey(programId);
export const nullKey = "11111111111111111111111111111111";

// key is string
// acc is PublicKey

// get staking type
export function getStaking(lockDay) {
  let stakingTypeList = stakingList.map((e) => {
    return e.lockDay;
  });
  let index = stakingTypeList.indexOf(lockDay);
  if (index == -1) {
    console.error("get staking index error");
    return;
  } else {
    return stakingList[index];
  }
}

// get registrar data
export async function getRegistrarData(connection, registrarKey) {
  // use account
  let registrarAcc = new PublicKey(registrarKey);
  let registrarData = await connection.getAccountInfo(registrarAcc);
  if (registrarData != null) {
    let accData = registrarData.data;
    let pubLen = 32;
    let authority = bs58.encode(accData.slice(0, pubLen), "hex");
    let nonce = readUInt8(accData.slice(pubLen, pubLen + 1));
    let withdrawalTimeLock = readLittleInt64(
      accData.slice(pubLen + 1, pubLen + 1 + 8)
    );
    let mint = bs58.encode(
      accData.slice(pubLen + 1 + 8, pubLen * 2 + 1 + 8),
      "hex"
    );
    let poolMint = bs58.encode(
      accData.slice(pubLen * 2 + 1 + 8, pubLen * 3 + 1 + 8),
      "hex"
    );
    let rewardMint = bs58.encode(
      accData.slice(pubLen * 3 + 1 + 8, pubLen * 4 + 1 + 8),
      "hex"
    );
    let rewardPoolMint = bs58.encode(
      accData.slice(pubLen * 4 + 1 + 8, pubLen * 5 + 1 + 8),
      "hex"
    );
    let stakePoolMint = bs58.encode(
      accData.slice(pubLen * 5 + 1 + 8, pubLen * 6 + 1 + 8),
      "hex"
    );
    let stakeRate = readLittleInt64(
      accData.slice(pubLen * 6 + 1 + 8, pubLen * 6 + 1 + 8 + 8)
    );
    let stakeSpt = bs58.encode(
      accData.slice(pubLen * 6 + 1 + 8 + 8, pubLen * 6 + 1 + 8 + 8 + 32),
      "hex"
    );
    return {
      authority,
      nonce,
      withdrawalTimeLock,
      mint,
      poolMint,
      rewardMint,
      rewardPoolMint,
      stakePoolMint,
      stakeRate,
      stakeSpt,
    };
  } else {
    console.error("registrar data is null");
    return;
  }
}

// get registrar pda
export async function getRegistrarPDA(registrarKey) {
  if (registrarKey == "") {
    return { registrarPDA: null, nonce: -1 };
  }
  let registrarAcc = new PublicKey(registrarKey);
  let pdaSeed = [registrarAcc.toBuffer()];
  let [registrarPDA, nonce] = await PublicKey.findProgramAddress(
    pdaSeed,
    programAcc
  );
  return { registrarPDA, nonce };
}

// get registrar pda
export async function getRegistrarPDA3(connection, registrarKey) {
  if (registrarKey == "") {
    return null;
  }
  let registrarData = await getRegistrarData(connection, registrarKey);
  let registrarAcc = new PublicKey(registrarKey);
  let pdaSeed = [registrarAcc.toBuffer(), Buffer.from([registrarData.nonce])];
  let registrarPDA = await PublicKey.createProgramAddress(pdaSeed, programAcc);
  return registrarPDA;
}

// get member key
export async function getMemberKey(wallet, memberSeed) {
  // get member key
  let memberAcc = await PublicKey.createWithSeed(
    wallet.publicKey,
    memberSeed,
    programAcc
  );
  return memberAcc.toBase58();
}

// find an empty member key
export async function getMemberKey2(wallet, memberSeed, connection) {
  var memberData = 1;
  var memberKey;
  var memberSeed2 = memberSeed;
  var i = 0;
  while (memberData) {
    await sleep(3000);
    // get member key
    let memberAcc = await PublicKey.createWithSeed(
      wallet.publicKey,
      memberSeed2,
      programAcc
    );
    memberData = await connection.getAccountInfo(memberAcc);
    if (!memberData) {
      memberKey = memberAcc.toBase58();
    } else {
      memberSeed2 = memberSeed + i.toString();
      i++;
    }
  }
  return { key: memberKey, seed: memberSeed2 };
}

// find all not empty member key
export async function getMemberKey3(wallet, memberSeed, connection) {
  var memberData = 1;
  var membersKey = [];
  var memberSeed2 = memberSeed;
  var i = 0;
  while (memberData) {
    await sleep(3000);
    // get member key
    let memberAcc = await PublicKey.createWithSeed(
      wallet.publicKey,
      memberSeed2,
      programAcc
    );
    memberData = await connection.getAccountInfo(memberAcc);
    if (memberData) {
      membersKey.push(memberAcc.toBase58());
      memberSeed2 = memberSeed + i.toString();
      i++;
    }
  }
  return membersKey;
}

// find all member key
export async function findMemberKey3(connection, wallet, registrarKey) {
  let userWalletAcc = wallet.publicKey;
  let programAcc = new PublicKey(programId);
  let config = {
    commitment: "confirmed",
    filters: [
      { memcmp: { offset: 0, bytes: registrarKey } },
      { memcmp: { offset: 32, bytes: userWalletAcc.toBase58() } },
      { dataSize: MEMBER_SIZE },
    ],
  };
  let list = await connection.getParsedProgramAccounts(programAcc, config);
  return list;
}

// get member pda
export async function getMemberPDA(connection, memberKey) {
  if (memberKey == "") {
    return { memberPDA: null, nonce: -1 };
  }
  let memberData = await getMemberData(connection, memberKey);
  let registrarAcc = new PublicKey(memberData.registrar);
  let memberAcc = new PublicKey(memberKey);
  let pdaSeed = [registrarAcc.toBuffer(), memberAcc.toBuffer()];
  let [memberPDA, nonce] = await PublicKey.findProgramAddress(
    pdaSeed,
    programAcc
  );
  return { memberPDA, nonce };
}

// get member pda
export async function getMemberPDA2(registrarKey, memberKey) {
  if (registrarKey == "" || memberKey == "") {
    return { memberPDA: null, nonce: -1 };
  }
  let registrarAcc = new PublicKey(registrarKey);
  let memberAcc = new PublicKey(memberKey);
  let pdaSeed = [registrarAcc.toBuffer(), memberAcc.toBuffer()];
  let [memberPDA, nonce] = await PublicKey.findProgramAddress(
    pdaSeed,
    programAcc
  );
  return { memberPDA, nonce };
}

// get member pda
export async function getMemberPDA3(connection, memberKey) {
  if (memberKey == "") {
    return null;
  }
  let memberData = await getMemberData(connection, memberKey);
  let registrarAcc = new PublicKey(memberData.registrar);
  let memberAcc = new PublicKey(memberKey);
  let pdaSeed = [
    registrarAcc.toBuffer(),
    memberAcc.toBuffer(),
    Buffer.from([memberData.nonce]),
  ];
  let memberPDA = await PublicKey.createProgramAddress(pdaSeed, programAcc);
  return memberPDA;
}

// get member data
export async function getMemberData(connection, memberKey) {
  let memberAcc = new PublicKey(memberKey);
  let memberData = await connection.getAccountInfo(memberAcc);
  if (memberData != null) {
    return getMemberDataRaw(memberData);
  } else {
    // console.error('member data is null');
    return;
  }
}

// get member data from binary data
export function getMemberDataRaw(memberData) {
  let accData = memberData.data;
  let pubLen = 32;
  let registrar = bs58.encode(accData.slice(0, pubLen), "hex");
  let beneficiary = bs58.encode(accData.slice(pubLen, pubLen * 2), "hex");
  let meta = bs58.encode(accData.slice(pubLen * 2, pubLen * 3), "hex");
  let spt = bs58.encode(accData.slice(pubLen * 3, pubLen * 4), "hex");
  let vault = bs58.encode(accData.slice(pubLen * 4, pubLen * 5), "hex");
  let rewardSpt = bs58.encode(accData.slice(pubLen * 5, pubLen * 6), "hex");
  let stakeSpt = bs58.encode(accData.slice(pubLen * 6, pubLen * 7), "hex");
  let lastStakeTs = readLittleInt64(accData.slice(pubLen * 7, pubLen * 7 + 8));
  let nonce = readUInt8(accData.slice(pubLen * 7 + 8, pubLen * 7 + 8 + 1));
  return {
    registrar,
    beneficiary,
    meta,
    spt,
    vault,
    rewardSpt,
    stakeSpt,
    lastStakeTs,
    nonce,
  };
}

// create member account
export async function createMemberAcc(
  connection,
  wallet,
  memberKey,
  memberSeed
) {
  // use account
  let walletAcc = wallet.publicKey;
  let memberAcc = new PublicKey(memberKey);
  //create member account
  const balanceNeeded = await connection.getMinimumBalanceForRentExemption(
    MEMBER_SIZE
  );
  const transaction = new Transaction().add(
    SystemProgram.createAccountWithSeed({
      fromPubkey: walletAcc,
      basePubkey: walletAcc,
      seed: memberSeed,
      newAccountPubkey: memberAcc,
      lamports: balanceNeeded,
      space: MEMBER_SIZE,
      programId: programAcc,
    })
  );
  transaction.feePayer = walletAcc;
  let { blockhash } = await connection.getRecentBlockhash();
  transaction.recentBlockhash = blockhash;
  try {
    // sign
    let signed = await wallet.signTransaction(transaction);
    try {
      // send transaction
      let signature = await connection.sendRawTransaction(signed.serialize());
      console.log("create member signature", signature);
      var memberData;
      let start = new Date();
      while (memberData == null) {
        await sleep(3000);
        memberData = await getMemberData(connection, memberKey);
        if (timeout(start, 180000)) {
          console.log("wait member acc time out");
          return { code: -2, msg: "time out" };
        }
      }
      return { code: 1, msg: "create member acc ok", data: memberData };
    } catch (e) {
      return { code: -3, msg: "create memeber account error", data: e };
    }
  } catch (e) {
    return { code: -1, msg: "sign cancelled", data: e };
  }
}

// get member vaults
export function getMemberVaults(memberData) {
  let vaultsKey = [
    memberData.spt,
    memberData.vault,
    memberData.rewardSpt,
    memberData.stakeSpt,
  ];
  if (
    vaultsKey[0] == nullKey ||
    vaultsKey[1] == nullKey ||
    vaultsKey[2] == nullKey ||
    vaultsKey[3] == nullKey
  ) {
    return { code: 0, msg: "vaults are null", data: vaultsKey };
  }
  return { code: 1, msg: "get vaults ok", data: vaultsKey };
}

export async function createMemberVaults(
  connection,
  wallet,
  registrarKey,
  memberKey
) {
  // use account
  let walletAcc = wallet.publicKey;
  let registrarData = await getRegistrarData(connection, registrarKey);
  let lpAcc = new PublicKey(registrarData.poolMint);
  let mpAcc = new PublicKey(registrarData.rewardPoolMint);
  let { memberPDA } = await getMemberPDA2(registrarKey, memberKey);
  const balanceNeeded = await connection.getMinimumBalanceForRentExemption(
    AccountLayout.span
  );
  const transaction = new Transaction();
  let accounts = [];
  let keys = [];
  // create lp spt
  let newAccount = Keypair.generate();
  accounts.push(newAccount);
  keys.push(newAccount.publicKey);
  transaction.add(
    SystemProgram.createAccount({
      fromPubkey: walletAcc,
      newAccountPubkey: newAccount.publicKey,
      lamports: balanceNeeded,
      space: AccountLayout.span,
      programId: TOKEN_PROGRAM_ID,
    }),
    Token.createInitAccountInstruction(
      TOKEN_PROGRAM_ID,
      lpAcc,
      newAccount.publicKey,
      memberPDA
    )
  );
  // create syp vault
  let newAccount2 = Keypair.generate();
  accounts.push(newAccount2);
  keys.push(newAccount2.publicKey);
  transaction.add(
    SystemProgram.createAccount({
      fromPubkey: walletAcc,
      newAccountPubkey: newAccount2.publicKey,
      lamports: balanceNeeded,
      space: AccountLayout.span,
      programId: TOKEN_PROGRAM_ID,
    }),
    Token.createInitAccountInstruction(
      TOKEN_PROGRAM_ID,
      sypAcc,
      newAccount2.publicKey,
      memberPDA
    )
  );
  // create reward spt vault
  let newAccount3 = Keypair.generate();
  accounts.push(newAccount3);
  keys.push(newAccount3.publicKey);
  transaction.add(
    SystemProgram.createAccount({
      fromPubkey: walletAcc,
      newAccountPubkey: newAccount3.publicKey,
      lamports: balanceNeeded,
      space: AccountLayout.span,
      programId: TOKEN_PROGRAM_ID,
    }),
    Token.createInitAccountInstruction(
      TOKEN_PROGRAM_ID,
      mpAcc,
      newAccount3.publicKey,
      memberPDA
    )
  );
  // check if user has stake spt
  let stakeSpt = await getTokenAccounts(connection, walletAcc, npAcc);
  if (!stakeSpt) {
    // create stake spt vault
    let newAccount4 = Keypair.generate();
    accounts.push(newAccount4);
    keys.push(newAccount4.publicKey);
    transaction.add(
      SystemProgram.createAccount({
        fromPubkey: walletAcc,
        newAccountPubkey: newAccount4.publicKey,
        lamports: balanceNeeded,
        space: AccountLayout.span,
        programId: TOKEN_PROGRAM_ID,
      }),
      Token.createInitAccountInstruction(
        TOKEN_PROGRAM_ID,
        npAcc,
        newAccount4.publicKey,
        walletAcc
      )
    );
  }
  transaction.feePayer = walletAcc;
  // send transaction
  let { blockhash } = await connection.getRecentBlockhash();
  transaction.recentBlockhash = blockhash;
  try {
    // sign
    let signed = await wallet.signTransaction(transaction);
    try {
      for (let i = 0; i < accounts.length; i++) {
        signed.partialSign(accounts[i]);
      }
      // send transaction
      let signatrue = await connection.sendRawTransaction(signed.serialize());
      console.log("create member vaults signatrue", signatrue);
      let data = accounts.map((e) => {
        return e.publicKey.toBase58();
      });
      if (stakeSpt) {
        data.push(stakeSpt.toBase58());
      }
      for (let i = 0; i < data.length; i++) {
        let start = new Date();
        let acc = new PublicKey(data[i]);
        let accData;
        while (!accData) {
          await sleep(3000);
          accData = await connection.getAccountInfo(acc);
          if (timeout(start, 180000)) {
            console.log("wait member vault acc time out");
            return { code: -2, msg: "time out" };
          }
        }
      }
      return { code: 1, msg: "create member vaults ok", data };
    } catch (e) {
      return { code: -3, msg: "create member vaults error", data: e };
    }
  } catch (e) {
    return { code: -1, msg: "sign cancelled", data: e };
  }
}

// create member account vaults data
export async function createMember(
  connection,
  wallet,
  memberKey,
  vaultsKey,
  registrarKey
) {
  // use account
  let walletAcc = wallet.publicKey;
  let registrarAcc = new PublicKey(registrarKey);
  let memberAcc = new PublicKey(memberKey);
  let { memberPDA, nonce } = await getMemberPDA2(registrarKey, memberKey);
  let sptAcc = new PublicKey(vaultsKey[0]);
  let vaultAcc = new PublicKey(vaultsKey[1]);
  let rewardSptAcc = new PublicKey(vaultsKey[2]);
  let stakeSptAcc;
  {
    let res = await getTokenAccountMaxAmount(connection, wallet, npMintKey);
    if (res.code == 1) {
      stakeSptAcc = res.data.acc;
    } else {
      return { code: -11, msg: "user has no np token account", data: null };
    }
  }
  // make instruction
  let trix = StakingInstruction.createMember(
    nonce,
    registrarAcc,
    memberAcc,
    memberPDA,
    walletAcc, //beneficiary
    sptAcc, //spt
    vaultAcc, // vault
    rewardSptAcc, //reward spt
    stakeSptAcc, //stake spt
    TOKEN_PROGRAM_ID,
    programAcc
  );
  let transaction = new Transaction();
  transaction.add(trix);
  transaction.feePayer = walletAcc;
  let { blockhash } = await connection.getRecentBlockhash();
  transaction.recentBlockhash = blockhash;
  try {
    // sign
    let signed = await wallet.signTransaction(transaction);
    try {
      // send trasaction
      let signature = await connection.sendRawTransaction(signed.serialize());
      let start = new Date();
      var code = 0;
      var memberData;
      while (code != 1) {
        await sleep(3000);
        memberData = await getMemberData(connection, memberKey);
        let temp = getMemberVaults(memberData);
        code = temp.code;
        if (timeout(start, 180000)) {
          return { code: -2, msg: "time out", data: signature };
        }
      }
      console.log("create member ok", memberData);
      return { code: 1, msg: "create member  ok", data: memberData };
    } catch (e) {
      return { code: -3, msg: "create memeber account error", data: e };
    }
  } catch (e) {
    return { code: -1, msg: "sign cancelled", data: e };
  }
}

async function createBalance(connection, wallet, mintAcc) {
  const balanceNeeded = await connection.getMinimumBalanceForRentExemption(
    AccountLayout.span
  );
  const transaction = new Transaction();
  let walletAcc = wallet.publicKey;
  let newAccount = Keypair.generate();
  let newAccountKey = newAccount.publicKey;
  transaction.add(
    SystemProgram.createAccount({
      fromPubkey: walletAcc,
      newAccountPubkey: newAccountKey,
      lamports: balanceNeeded,
      space: AccountLayout.span,
      programId: TOKEN_PROGRAM_ID,
    })
  );
  transaction.add(
    Token.createInitAccountInstruction(
      TOKEN_PROGRAM_ID,
      mintAcc,
      newAccount.publicKey,
      pda
    )
  );
  transaction.feePayer = walletAcc;
  let { blockhash } = await connection.getRecentBlockhash();
  transaction.recentBlockhash = blockhash;
  var signed;
  try {
    signed = await wallet.signTransaction(transaction);
  } catch (e) {
    console.error("staking create member account sign cancelled", e);
    return { code: -1, msg: "sign cancelled" };
  }
  await connection.sendRawTransaction(signed.serialize());
}

/**
 * get how much syp coin user have
 * @param {import("@solana/web3.js").Connection} connection
 * @param {{publicKey:import("@solana/web3.js").PublicKey}} wallet
 * @returns {Promise<{acc:import("@solana/web3.js").PublicKey|null;amount:number;decimal:number}>}
 */
export async function getBalanceSyp(connection, wallet) {
  // use account
  let walletAcc = wallet.publicKey;
  let balance = await getBalanceSingle(connection, walletAcc, sypAcc);
  return balance;
}

// get how much lp has been stake
export async function getBalanceSpt(connection, memberKey) {
  let memberData = await getMemberData(connection, memberKey);
  if (memberData.spt != nullKey) {
    let sptAcc = new PublicKey(memberData.spt);
    let res = await connection.getTokenAccountBalance(sptAcc);
    if (res != undefined) {
      let balance = {
        acc: memberData.spt,
        amount: parseFloat(res.value.uiAmount),
        decimal: parseFloat(res.value.decimals),
      };
      return balance;
    } else {
      let balance = { acc: memberData.spt, amount: 0, decimal: 9 };
      return balance;
    }
  } else {
    let balance = { acc: memberData.spt, amount: 0, decimal: 9 };
    return balance;
  }
}

// calculate how much reward user could get
export function calculateSptReward(memberData, balanceSpt, stakeRate) {
  let nowTime = new Date().getTime();
  let stakeTime = memberData.lastStakeTs * 1000;
  let d = Math.floor((nowTime - stakeTime) / (1000 * 3600 * 24));
  let rewardAmount = (d * balanceSpt.amount * stakeRate) / 365;
  return rewardAmount;
}

// calculate how much reward user could get in second
export function calculateSptReward2(memberData, balanceSpt, stakeRate) {
  let nowTime = new Date().getTime();
  let stakeTime = memberData.lastStakeTs * 1000;
  let d = nowTime - stakeTime;
  let rewardAmount =
    (d * balanceSpt.amount * stakeRate) / (1000 * 3600 * 24 * 365);
  return rewardAmount;
}

// get how much reward user have
export async function getBalanceRewardSpt(connection, memberKey) {
  let memberData = await getMemberData(connection, memberKey);
  let rewardSptAcc = new PublicKey(memberData.rewardSpt);
  if (memberData.spt != nullKey) {
    let res = await connection.getTokenAccountBalance(rewardSptAcc);
    if (res != undefined) {
      let balance = {
        acc: rewardSptAcc.toBase58(),
        amount: parseFloat(res.value.uiAmount),
        decimal: parseFloat(res.value.decimals),
      };
      return balance;
    }
  } else {
    let balance = { acc: memberData.spt, amount: 0, decimal: 9 };
    return balance;
  }
}

export async function getVendorData(connection, vendorKey) {
  let vendorAcc = new PublicKey(vendorKey);
  let vendorData = await connection.getAccountInfo(vendorAcc);
  if (vendorData != null) {
    let accData = vendorData.data;
    let pubLen = 32;
    let registrar = bs58.encode(accData.slice(0, pubLen), "hex");
    let rewardMint = bs58.encode(accData.slice(pubLen, pubLen * 2), "dec");
    let rewardPoolMint = bs58.encode(
      accData.slice(pubLen * 2, pubLen * 3),
      "hex"
    );
    let vault = bs58.encode(accData.slice(pubLen * 3, pubLen * 4), "hex");
    let manager = bs58.encode(accData.slice(pubLen * 4, pubLen * 5), "hex");
    let nonce = readUInt8(accData.slice(pubLen * 5, pubLen * 5 + 1));
    let total = readLittleInt64(
      accData.slice(pubLen * 5 + 1, pubLen * 5 + 1 + 8)
    );
    let lastDropTime = readLittleInt64(
      accData.slice(pubLen * 5 + 1 + 8, pubLen * 5 + 1 + 8 + 8)
    );
    let expiryTime = readLittleInt64(
      accData.slice(pubLen * 5 + 1 + 8 + 8, pubLen * 5 + 1 + 8 + 8 + 8)
    );
    return {
      registrar,
      rewardMint,
      rewardPoolMint,
      vault,
      manager,
      nonce,
      total,
      lastDropTime,
      expiryTime,
    };
  } else {
    console.error("vendor data is null");
    return;
  }
}

// get vendor pda
export async function getVendorPDA2(registrarKey, vendorKey) {
  if (registrarKey == "" || vendorKey == "") {
    return { vendorPDA: null, nonce: -1 };
  }
  let registrarAcc = new PublicKey(registrarKey);
  let vendorAcc = new PublicKey(vendorKey);
  let pdaSeed = [registrarAcc.toBuffer(), vendorAcc.toBuffer()];
  let [vendorPDA, nonce] = await PublicKey.findProgramAddress(
    pdaSeed,
    programAcc
  );
  return { vendorPDA, nonce };
}

// get vendor pda
export async function getVendorPDA3(connection, vendorKey) {
  if (vendorKey == "") {
    return null;
  }
  let vendorData = await getVendorData(connection, vendorKey);
  let registrarAcc = new PublicKey(vendorData.registrar);
  let vendorAcc = new PublicKey(vendorKey);
  let pdaSeed = [
    registrarAcc.toBuffer(),
    vendorAcc.toBuffer(),
    Buffer.from([vendorData.nonce]),
  ];
  let vendorPDA = await PublicKey.createProgramAddress(pdaSeed, programAcc);
  return vendorPDA;
}

export async function stake(connection, wallet, amount, memberKey) {
  // use account
  let walletAcc = wallet.publicKey;
  let memberData = await getMemberData(connection, memberKey);
  let registrarAcc = new PublicKey(memberData.registrar);
  let registrarData = await getRegistrarData(connection, memberData.registrar);
  let registrarPDA = await getRegistrarPDA3(connection, memberData.registrar);
  let memberAcc = new PublicKey(memberKey);
  // let { memberPDA, nonce } = await getMemberPDA(memberKey);
  let userSypAcc = await getTokenAccounts(connection, walletAcc, sypAcc);
  let stakeSptAcc;
  {
    let res = await getTokenAccountMaxAmount(connection, wallet, npMintKey);
    if (res.code == 1) {
      stakeSptAcc = res.data.acc;
    } else {
      return { code: -11, msg: "user has no np token account", data: null };
    }
  }
  // make instruction
  let trix = StakingInstruction.directStake(
    amount * 10 ** 9,
    registrarAcc,
    new PublicKey(registrarData.stakeSpt), //registrar stake spt
    registrarPDA,
    new PublicKey(registrarData.poolMint), //pool mint, is spt mint
    new PublicKey(registrarData.rewardPoolMint), //reward pool mint
    new PublicKey(registrarData.stakePoolMint), //stake pool mint
    memberAcc,
    walletAcc, //beneficiary
    userSypAcc, //from
    new PublicKey(memberData.spt), //spt
    new PublicKey(memberData.vault), //vault
    new PublicKey(memberData.rewardSpt), //reward spt
    stakeSptAcc,
    SYSVAR_CLOCK_PUBKEY,
    TOKEN_PROGRAM_ID,
    programAcc
  );
  let transaction = new Transaction();
  transaction.add(trix);
  transaction.feePayer = walletAcc;
  let { blockhash } = await connection.getRecentBlockhash();
  transaction.recentBlockhash = blockhash;
  try {
    // sign
    let signed = await wallet.signTransaction(transaction);
    try {
      // send transaction
      let signature = await connection.sendRawTransaction(signed.serialize());
      return { code: 1, msg: "stake ok", data: signature };
    } catch (e) {
      return { code: -3, msg: "stake error", data: e };
    }
  } catch (e) {
    return { code: -1, msg: "sign cancelled", data: e };
  }
}

export async function unstake(connection, wallet, amount, memberKey) {
  // use account
  let walletAcc = wallet.publicKey;
  let memberData = await getMemberData(connection, memberKey);
  let registrarAcc = new PublicKey(memberData.registrar);
  let registrarData = await getRegistrarData(connection, memberData.registrar);
  let registrarPDA = await getRegistrarPDA3(connection, memberData.registrar);
  let memberAcc = new PublicKey(memberKey);
  let memberPDA = await getMemberPDA3(connection, memberKey);
  let userSypAcc = await getTokenAccounts(connection, walletAcc, sypAcc);
  let stakeSptAcc;
  {
    let res = await getTokenAccountMaxAmount(connection, wallet, npMintKey);
    if (res.code == 1) {
      stakeSptAcc = res.data.acc;
    } else {
      return { code: -11, msg: "user has no np token account", data: null };
    }
  }
  // make instruction
  let trix = StakingInstruction.directUnstake(
    amount * 10 ** 9,
    registrarAcc,
    new PublicKey(registrarData.stakeSpt),
    registrarPDA,
    new PublicKey(registrarData.poolMint), //pool mint, is spt mint
    new PublicKey(registrarData.rewardPoolMint), //reward pool mint
    memberAcc,
    memberPDA,
    walletAcc, //beneficiary
    userSypAcc, //to
    new PublicKey(memberData.spt), //spt
    new PublicKey(memberData.vault), //vault
    new PublicKey(memberData.rewardSpt), //reward spt
    stakeSptAcc,
    TOKEN_PROGRAM_ID,
    SYSVAR_CLOCK_PUBKEY,
    programAcc
  );
  let transaction = new Transaction();
  transaction.add(trix);
  transaction.feePayer = walletAcc;
  let { blockhash } = await connection.getRecentBlockhash();
  transaction.recentBlockhash = blockhash;
  try {
    // sign
    let signed = await wallet.signTransaction(transaction);
    try {
      // send transaction
      let signature = await connection.sendRawTransaction(signed.serialize());
      return { code: 1, msg: "unstake ok", data: signature };
    } catch (e) {
      return { code: -3, msg: "unstake error", data: e };
    }
  } catch (e) {
    return { code: -1, msg: "sign cancelled", data: e };
  }
}

export async function harvest(connection, wallet, memberKey, vendorKey) {
  // use account
  let walletAcc = wallet.publicKey;
  let memberData = await getMemberData(connection, memberKey);
  let memberPDA = await getMemberPDA3(connection, memberKey);
  let registrarAcc = new PublicKey(memberData.registrar);
  let registrarData = await getRegistrarData(connection, memberData.registrar);
  let vendorData = await getVendorData(connection, vendorKey);
  let vendorPDA = await getVendorPDA3(connection, vendorKey);
  let userSypAcc = await getTokenAccounts(connection, walletAcc, sypAcc);
  // make instruction
  let trix = StakingInstruction.claimReward(
    registrarAcc,
    new PublicKey(registrarData.rewardPoolMint),
    new PublicKey(memberKey),
    walletAcc,
    userSypAcc,
    new PublicKey(memberData.spt),
    new PublicKey(memberData.rewardSpt),
    memberPDA,
    new PublicKey(vendorKey),
    new PublicKey(vendorData.vault),
    vendorPDA,
    TOKEN_PROGRAM_ID,
    SYSVAR_CLOCK_PUBKEY,
    programAcc
  );
  let transaction = new Transaction();
  transaction.add(trix);
  transaction.feePayer = walletAcc;
  let { blockhash } = await connection.getRecentBlockhash();
  transaction.recentBlockhash = blockhash;
  try {
    // sign
    let signed = await wallet.signTransaction(transaction);
    try {
      // send transaction
      let signature = await connection.sendRawTransaction(signed.serialize());
      return { code: 1, msg: "harvest ok", data: signature };
    } catch (e) {
      return { code: -3, msg: "harvest error", data: e };
    }
  } catch (e) {
    return { code: -1, msg: "sign cancelled", data: e };
  }
}

export async function withdrawal(
  connection,
  wallet,
  amount,
  memberKey,
  vendorKey
) {
  // use account
  let walletAcc = wallet.publicKey;
  let memberData = await getMemberData(connection, memberKey);
  let memberPDA = await getMemberPDA3(connection, memberKey);
  let registrarAcc = new PublicKey(memberData.registrar);
  let registrarData = await getRegistrarData(connection, memberData.registrar);
  let registrarPDA = await getRegistrarPDA3(connection, memberData.registrar);
  let vendorData = await getVendorData(connection, vendorKey);
  let vendorPDA = await getVendorPDA3(connection, vendorKey);
  let userSypAcc = await getTokenAccounts(connection, walletAcc, sypAcc);
  let stakeSptAcc;
  {
    let res = await getTokenAccountMaxAmount(connection, wallet, npMintKey);
    if (res.code == 1) {
      stakeSptAcc = res.data.acc;
    } else {
      return { code: -11, msg: "user has no np token account", data: null };
    }
  }
  // make unstake instruction
  let trix = StakingInstruction.directUnstake(
    amount * 10 ** 9,
    registrarAcc,
    new PublicKey(registrarData.stakeSpt),
    registrarPDA,
    new PublicKey(registrarData.poolMint), //pool mint, is spt mint
    new PublicKey(registrarData.rewardPoolMint), //reward pool mint
    new PublicKey(memberKey),
    memberPDA,
    walletAcc, //beneficiary
    userSypAcc, //to
    new PublicKey(memberData.spt), //spt
    new PublicKey(memberData.vault), //vault
    new PublicKey(memberData.rewardSpt), //reward spt
    stakeSptAcc,
    TOKEN_PROGRAM_ID,
    SYSVAR_CLOCK_PUBKEY,
    programAcc
  );
  // make harvest instruction
  let trix2 = StakingInstruction.claimReward(
    registrarAcc,
    new PublicKey(registrarData.rewardPoolMint),
    new PublicKey(memberKey),
    walletAcc,
    userSypAcc,
    new PublicKey(memberData.spt),
    new PublicKey(memberData.rewardSpt),
    memberPDA,
    new PublicKey(vendorKey),
    new PublicKey(vendorData.vault),
    vendorPDA,
    TOKEN_PROGRAM_ID,
    SYSVAR_CLOCK_PUBKEY,
    programAcc
  );
  let transaction = new Transaction();
  transaction.add(trix);
  transaction.add(trix2);
  transaction.feePayer = walletAcc;
  let { blockhash } = await connection.getRecentBlockhash();
  transaction.recentBlockhash = blockhash;
  try {
    // sign
    let signed = await wallet.signTransaction(transaction);
    try {
      // send transaction
      let signature = await connection.sendRawTransaction(signed.serialize());
      return { code: 1, msg: "unstake ok", data: signature };
    } catch (e) {
      return { code: -3, msg: "unstake error", data: e };
    }
  } catch (e) {
    return { code: -1, msg: "sign cancelled", data: e };
  }
}

export async function getTotalStaking(connection) {
  var sptAmount = 0;
  for (let i = 0; i < stakingList.length; i++) {
    let registrarData = await getRegistrarData(
      connection,
      stakingList[i].registrarKey
    );
    if (registrarData) {
      if (registrarData.poolMint != nullKey) {
        let res = await connection.getTokenSupply(
          new PublicKey(registrarData.poolMint)
        );
        if (res) {
          sptAmount += res.value.uiAmount;
        } else {
          console.error("get staking spt balance error");
          return 0;
        }
      } else {
        console.error("get staking spt balance error");
        return 0;
      }
    }
  }
  return sptAmount;
}

export async function getUserTotalStaking(connection, wallet) {
  let walletAcc = wallet.publicKey;
  let balance = await getBalance(connection, walletAcc, npAcc);
  let amount = 0.0;
  if (balance) {
    amount = balance.totalBalance;
  }
  return amount;
}

export async function getStakingFeeDiscount(connection, walletAcc) {
  let balance = await getBalanceSingle(connection, walletAcc, npAcc);
  let value = balance.amount;
  if (!balance) {
    value = 0;
  }
  let temp = feeDiscount.filter((e) => {
    return value >= e.min && value < e.max;
  });
  if (temp.length == 1) {
    return temp[0];
  } else {
    console.log("find fee discount error", temp);
    return;
  }
}

export async function getAllRegistrarData(connection) {
  for (let i = 0; i < stakingList.length; i++) {
    let registrarData = await getRegistrarData(
      connection,
      stakingList[i].registrarKey
    );
    if (registrarData) {
      console.log("registrar data", i, registrarData);
    }
  }
}

export async function dropReward(connection, wallet, amount, vendorKey) {
  // use account
  let walletAcc = wallet.publicKey;
  let vendorData = await getVendorData(connection, vendorKey);
  let registrarAcc = new PublicKey(vendorData.registrar);
  let vendorAcc = new PublicKey(vendorKey);
  let vendorVaultAcc = new PublicKey(vendorData.vault);
  let vendorPDA = await getVendorPDA3(connection, vendorKey);
  let fromAcc = await getTokenAccounts(connection, walletAcc, sypAcc);
  // make instruction
  let trix = StakingInstruction.dropReward(
    amount * 10 ** 9,
    registrarAcc,
    vendorAcc,
    vendorVaultAcc,
    vendorPDA,
    walletAcc,
    fromAcc,
    SYSVAR_CLOCK_PUBKEY,
    TOKEN_PROGRAM_ID,
    programAcc
  );
  let transaction = new Transaction();
  transaction.add(trix);
  transaction.feePayer = walletAcc;
  let { blockhash } = await connection.getRecentBlockhash();
  transaction.recentBlockhash = blockhash;
  try {
    // sign
    let signed = await wallet.signTransaction(transaction);
    try {
      // send transaction
      let signature = await connection.sendRawTransaction(signed.serialize());
      return { code: 1, msg: "unstake ok", data: signature };
    } catch (e) {
      return { code: -3, msg: "unstake error", data: e };
    }
  } catch (e) {
    return { code: -1, msg: "sign cancelled", data: e };
  }
}

export async function getVendorVaultAmount(connection, vendorKey) {
  let vendorData = await getVendorData(connection, vendorKey);
  let vendorVaultAcc = new PublicKey(vendorData.vault);
  let res = await connection.getTokenAccountBalance(vendorVaultAcc);
  if (res != undefined) {
    let balance = {
      acc: vendorVaultAcc.toBase58(),
      amount: parseFloat(res.value.uiAmount),
      decimal: parseFloat(res.value.decimals),
    };
    return balance;
  }
}

export async function getTotalStakingAmount(connection, registrarKey) {
  let registrarData = await getRegistrarData(connection, registrarKey);
  let lpMintAcc = new PublicKey(registrarData.poolMint);
  let res = await connection.getTokenSupply(lpMintAcc);
  if (res != undefined) {
    let balance = {
      acc: lpMintAcc.toBase58(),
      amount: parseFloat(res.value.uiAmount),
      decimal: parseFloat(res.value.decimals),
    };
    return balance;
  }
}

export async function getStakingSptAmount(connection, registrarKey) {
  let registrarData = await getRegistrarData(connection, registrarKey);
  let stakingSptAcc = new PublicKey(registrarData.stakeSpt);
  let res = await connection.getTokenAccountBalance(stakingSptAcc);
  if (res != undefined) {
    let balance = {
      acc: stakingSptAcc.toBase58(),
      amount: parseFloat(res.value.uiAmount),
      decimal: parseFloat(res.value.decimals),
    };
    return balance;
  }
}

export async function findMemberKey(connection, registrarKey) {
  let programAcc = new PublicKey(programId);
  let config = {
    commitment: "confirmed",
    filters: [
      { memcmp: { offset: 0, bytes: registrarKey } },
      { dataSize: MEMBER_SIZE },
    ],
  };
  let list = await connection.getParsedProgramAccounts(programAcc, config);
  return list;
}

export async function findMemberKeyByBeneficiary(connection, userWalletKey) {
  let programAcc = new PublicKey(programId);
  let config = {
    commitment: "confirmed",
    filters: [
      { memcmp: { offset: 32, bytes: userWalletKey } },
      { dataSize: MEMBER_SIZE },
    ],
  };
  let list = await connection.getParsedProgramAccounts(programAcc, config);
  return list;
}

export async function expiryReward(
  connection,
  wallet,
  registrarKey,
  vendorKey
) {
  let programAcc = new PublicKey(programId);
  let staking = stakingList.find((e) => {
    return e.registrarKey == registrarKey;
  });
  if (!staking) {
    return { code: -1, msg: "null staking" };
  }
  let vendorData = await getVendorData(connection, vendorKey);
  if (vendorData.registrar != registrarKey) {
    return { code: -1, msg: "registrar not match" };
  }
  let userTokenKey;
  {
    let res = await getTokenAccountMaxAmount(connection, wallet, SYPMINT);
    if (res.code == 1) {
      userTokenKey = res.data.acc.toBase58();
    } else {
      return res;
    }
  }
  let tx = new Transaction().add(
    StakingInstruction.expiryReward(
      new PublicKey(registrarKey),
      new PublicKey(vendorKey),
      new PublicKey(vendorData.vault),
      new PublicKey(staking.vendorPDAKey),
      new PublicKey(userTokenKey),
      TOKEN_PROGRAM_ID,
      SYSVAR_CLOCK_PUBKEY,
      programAcc
    )
  );
  let res = await signAndSendTransaction(connection, wallet, null, tx);
  return res;
}
